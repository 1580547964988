import React from 'react';
import './App.css';
import { provide } from '@hilma/tools';
import { Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import { Products } from './pages/Products';
import EditProduct from './components/EditProduct/EditProduct';
import { muiTheme } from './common/consts/theme';
import { Alert, AlertProvider, I18nProvider, useFilesUpload } from '@hilma/forms';
import { Homepage } from './pages/Homepage';
import { CreateTutorials } from './pages/CreateTutorials';
import { ProductsProvider } from './context/products.context';
import { Navbar } from './components/Navbar';
import { AuthProvider, PrivateRoute } from '@hilma/auth';
import { Collaborators } from './pages/Collaborators';
import Categories from './pages/Categories';
import { NotFound } from './components/NotFound';

function App() {
  useFilesUpload()

  return (
    <div className="App">
      <Navbar />
      <div className='under-nav'>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="login" element={<Login />} />
          <Route path="products" element={<PrivateRoute componentName="products-101" component={<Products />} />} />
          <Route path="products/:name" element={<PrivateRoute componentName="edit-product-101" component={<EditProduct />} />} />
          <Route path="tutorials" element={<PrivateRoute componentName="tutorials-101" component={<CreateTutorials />} />} />
          <Route path="collaborators" element={<PrivateRoute componentName="collaborators-101" component={<Collaborators />} />} />
          <Route path="categories" element={<PrivateRoute componentName="categories-101" component={<Categories />} />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Alert />
    </div>
  );
}

export default provide(
  [AuthProvider, { accessTokenCookie: 'kloklo' }],
  [I18nProvider, { router: false, muiPalette: muiTheme }],
  AlertProvider,
  ProductsProvider
)(App);
<Route path="tutorials" element={<CreateTutorials />} />
