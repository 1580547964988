import React, { useState } from "react"
import CreateProductPopup from "../components/CreateProductPopup";
import { ImageOutlined } from '@material-ui/icons';
import { useProducts } from "../context/products.context";
import { useStyles } from "../components/EditProduct/EditProductDetails";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import './styles/AdminProducts.scss';

export const Products: React.FC<{}> = () => {
    const [open, setOpen] = useState(false);
    const products = useProducts();
    const classes = useStyles();

    const changeOpen = () => {
        setOpen(prev => !prev);
    }

    return <div id="admin-products-page">
        <span className="top-container">
            <h3>מוצרים</h3>
            <Button
                onClick={changeOpen}
                className={clsx(classes.button, classes.margin, classes.addTutorial)} >
                <h3>+ הוספה<br /></h3>
            </Button>

        </span>
        <div className="products-container">

            {products.length === 0 ? <div>עוד אין מוצרים</div> : products.map(item => <Link key={item.name} to={`/products/${item.enName}`}>
                <span className="product" key={item.enName}>
                    <div className='img-container'>
                        {item.thumbnail?.image ? <img src={item.thumbnail?.image} /> : <ImageOutlined className='image' />}
                    </div>
                    <h4>{item.heName}</h4>
                </span>
            </Link>)}

            <CreateProductPopup
                open={open}
                close={changeOpen}
            />
        </div>
    </div>
}