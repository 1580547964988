import { FormFastInput } from "@hilma/forms"
import { Collapse, ListItem } from "@material-ui/core"
import React, { useState } from "react"

interface DownloadLinksProps {
    id: string,

}
export const DownloadLinks: React.FC<DownloadLinksProps> = ({ id }) => {
    const [open, setOpen] = useState(false);
    return <div className="box">
        <ListItem onClick={() => setOpen(v => !v)}>
            <h4>קישורים למוצר</h4>
        </ListItem>
        <Collapse in={open}>
            <FormFastInput id={`${id}.web`} description="קישור לאתר האינטרנט" />
            <FormFastInput id={`${id}.android`} description="קישור לחנות האפליקציות" />
            <FormFastInput id={`${id}.ios`} description="קישור לחנות IOS" />
            <FormFastInput id={`${id}.leaveDetailsIframe`} description="קישור לטופס להשארת פרטים" />
        </Collapse>
    </div>
}