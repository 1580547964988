import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import { createRules, useForm, FormFastInput, useAlert, FormProvider, Localize } from "@hilma/forms"
import React, { FC } from "react"
import { provide } from "@hilma/tools"
import * as Yup from 'yup';
import axios from "axios"
import { useNavigate } from "react-router-dom";
import { useAddSolutionToList } from "../context/products.context";
import { ProductInfo } from "../common/types";
import RadioGroupInput from "./RadioGroupInput";

interface CreateProductPopupProps {
    open: boolean;
    close: () => void;
}

export enum LoginType {
    GOOGLE = 'google',
    REGULAR = 'regular'
}

export type ILoginTypeValue = {
    readonly [status in LoginType]: string;
};

export const HEBREW_LOGIN_TYPE: ILoginTypeValue = {
    google: "גוגל",
    regular: "הרשמה רגילה"
}

export const createProductRules = createRules({
    heName: { required: true },
    enName: { required: true },
    domain: { required: true },
    loginType: { required: true }
})

export const createProduct_initialValues = {
    heName: "",
    enName: "",
    domain: "",
    loginType: LoginType.REGULAR
}

const CreateProductSchema = Yup.object().shape({
    heName: Yup.string()
        .min(2, 'Too Short!')
        .max(100, 'Too Long!')
        .required('Required'),
    enName: Yup.string()
        .min(2, 'Too Short!')
        .max(100, 'Too Long!')
        .required('Required'),
    domain: Yup.string()
        .min(10, 'Too Short!')
        .max(225, 'Too Long!')
        .required('Required'),
    loginType: Yup.string()
        .oneOf(Object.values(LoginType))
        .required({ path: 'Required' }),
});


export const CreateProductPopup: FC<CreateProductPopupProps> = ({ open, close }) => {
    const form = useForm();
    const alert = useAlert();
    const navigate = useNavigate();
    const addToList = useAddSolutionToList();

    const save = async () => {
        if (!form.isValid) {
            console.error("ERRORS", form.errors)
            alert("ישנן שגיאות, הטופס לא נשמר")
            return;
        }
        const values = JSON.parse(JSON.stringify(form.values));
        try {
            const { data } = await axios.post<Localize<ProductInfo>>("/api/product/new", {
                ...values
            })
            console.log(data);
            alert("יצירת הפרוייקט הצליחה", "success");
            close();
            addToList(values);
            navigate(`/products/${data}`);
        } catch (err) {
            alert("יצירת הפרוייקט נכשלה")
        }
    }

    return (
        <Dialog
            open={open}
            onClose={close} >
            <DialogTitle>
                פרטי המוצר החדש :)
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <FormFastInput id="heName" description={"שם הפרויקט בעברית"} />
                    <FormFastInput id="enName" description={"שם הפרויקט באנגלית"} />
                    <FormFastInput id="domain" description={"דומיין"} />
                    <RadioGroupInput disabled={false} id={"loginType"} description={'סוג ההתחברות למוצר'} options={[{ id: LoginType.GOOGLE, value: HEBREW_LOGIN_TYPE.google }, { id: LoginType.REGULAR, value: HEBREW_LOGIN_TYPE.regular }]} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>
                    ביטול
                </Button>
                <Button onClick={save}>
                    הוספה
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default provide([FormProvider, {
    initialValues: createProduct_initialValues,
    rules: createProductRules,
    validationSchema: CreateProductSchema,
    validateOnBlur: true
}])(CreateProductPopup);
