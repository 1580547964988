import { useForm } from "@hilma/forms";
import { Chip, Collapse, ListItem } from "@material-ui/core";
import { FC, useState } from "react";
import { DragDropContext, Droppable, Draggable, DropResult } from "react-beautiful-dnd";
import styles from '../styles/OrderPage.module.scss';
import { useMemo } from "react";
import { StripType } from "../../common/enums/strip-type.enum";
import clsx from "clsx";


interface OrderProductPageProps {
    id: 'order'
}

const translation: Record<StripType, string> = {
    Features: "פיצרים של המוצר",
    Gallery: "גלרית תמונות",
    OrganizationPurchase: "איזור רכישת ארגונים",
    Recommenders: "ממליצים",
    Tutorials: "הדרכות קרובות",
    Video: "וידיאו של המוצר"
};
const reorder = (list: Array<any>, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};
export const OrderProductPage: FC<OrderProductPageProps> = ({ id }) => {
    const [open, setOpen] = useState(false);

    const form = useForm();
    let { order } = form.values;

    const unusedItems = useMemo<StripType[]>(() => {
        const options = Object.keys(StripType) as StripType[];
        if (order.length === options.length)
            return [];
        let res: StripType[] = [];
        options.forEach((item) => {
            if (!order.includes(item))
                res.push(item);
        })
        return res;

    }, [order.length])




    function onDragEnd(result: DropResult) {
        if (!result.destination) {
            return;
        }

        if (result.destination.index === result.source.index) {
            return;
        }

        const new_order = reorder(
            order,
            result.source.index,
            result.destination.index
        );

        form.setFieldValue('order', new_order);
    }

    function removeSection(index: number) {
        order.splice(index, 1)
        form.setFieldValue('order', order);
    }

    function addSection(item: StripType) {
        form.setFieldError('order', order.push(item))
    }

    return <div className="box">
        <ListItem onClick={() => setOpen(v => !v)}>
            <h3>{"סדר הופעת החלקים בעמוד"}</h3>

        </ListItem>
        <Collapse in={open} style={{ marginTop: "5%" }}>
            ניתן לגרור את הרכיבים כדי לסדר מחדש את העמוד
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="list">
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {order.map((item: StripType, index: number) => (
                                <Draggable draggableId={item} key={item} index={index}>
                                    {provided => <div

                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}>
                                        <div className={clsx(styles.singleRow, styles.cursor)}>
                                            <p>
                                                {translation[item]}
                                            </p>
                                            <img src="/admin-icons/x.svg" height="15px" width="15px" className={styles.deleteItem}
                                                onClick={() => removeSection(index)} />
                                        </div>

                                    </div>
                                    }</Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}

                </Droppable>
            </DragDropContext>

            הוסף:

            {unusedItems.map(item => <div key={item} className={styles.singleRow}>
                <p>{translation[item]}</p>
                <button className={styles.addBtn} onClick={() => addSection(item)}>+</button>
            </div>)}
        </Collapse>
    </div>

}