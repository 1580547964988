import { useIsAuthenticated, useLogout } from '@hilma/auth';
import { Avatar, Badge, Button, IconButton, Menu, MenuItem } from '@material-ui/core';
import { ReactComponent as Profile } from '../icons/profile_blue.svg';
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import './styles/Navbar.scss'

export const Navbar = () => {
    const [dropList, setDropList] = useState<boolean>(false);
    const location = useLocation();
    const menu = useRef<HTMLDivElement>(null);
    const isAuthenticated = useIsAuthenticated();
    const logout = useLogout();
    const navigate = useNavigate()

    const links = useMemo(() => {
        return isAuthenticated ? [
            { text: 'בית', to: `/` },
            { text: 'מוצרים', to: `/products` },
            { text: 'הדרכות', to: `/tutorials` },
            { text: 'גוף מפתח', to: `/collaborators` },
            { text: 'קטגוריות', to: `/categories` }
        ] : []
    }, [isAuthenticated]);

    const profileOrLogin = () => {
        if (!isAuthenticated) return navigate(`/login`);
        setDropList(prev => !prev)
    }

    const logoutAndCloseNavBar = async (e: MouseEvent) => {
        e.stopPropagation();
        setDropList(false);
        await logout();
        navigate('/')
    }

    const isOption = (link: string) => {
        return (link === location.pathname || link.concat('/') === location.pathname) || (location.pathname.includes(link) && link !== "/");
    }

    return <div className="nav-container">
        <nav className="nav">
            <Link to="/">
                <img src="/admin-icons/Hilma.png" /> {/* is display none in portrait */}
            </Link>
            <div className="nav-options">
                {links.map((link) => <Link key={link.text} to={link.to}>
                    <div className={`option ${isOption(link.to)}`}>
                        {link.text}
                    </div>
                </Link>)}
            </div>
            <div className="profile-icon-container" onClick={profileOrLogin} ref={menu}>

                {isAuthenticated ?
                    <IconButton  >

                        <Avatar style={{ backgroundColor: '#f4f4f4' }}>
                            <Profile />
                        </Avatar>
                    </IconButton> :
                    <Button variant="outlined" className="login-button">התחבר</Button>}


                <Menu
                    open={dropList}
                    dir={'rtl'}
                    getContentAnchorEl={null}
                    anchorEl={menu.current}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'center' }}>

                    <MenuItem className="dd-menu-item" onClick={logoutAndCloseNavBar as unknown as MouseEventHandler<HTMLLIElement>}>התנתק</MenuItem>
                </Menu>

            </div>
        </nav>
    </div>
}