import { FC, useRef } from "react";
import { UploadedFile, useFiles } from '@hilma/fileshandler-client';
import { Button, makeStyles } from "@material-ui/core";
import { useImmer } from "use-immer";
import clsx from "clsx";

import GalleryImage from "./GalleryImage";
import { useAlert, useForm } from "@hilma/forms";

interface ProductImageGalleryProps {
    images?: string[];
    id: string;
    updateImages: (images: string[]) => void
}
export const ProductImageGallery: FC<ProductImageGalleryProps> = ({ id, images: _images, updateImages }) => {

    const [images, setImages] = useImmer(_images!);
    const [files, setFiles] = useImmer<UploadedFile[]>([]);
    const removedFiles = useRef<string[]>([])
    const filesUploader = useFiles()
    const classes = useStyles();
    const alert = useAlert();
    const form = useForm();
    async function saveChanges() {
        try {
            form.setSubmitting(true);
            const { data: updated } = await filesUploader.post<string[]>('/api/product/updateGallery', { removedFiles: removedFiles.current, id })
            form.setSubmitting(false);

            alert('התמונות נשמרו בהצלחה', 'success');
            updateImages(updated);

        } catch (err) {
            console.error("error in gallery:", err);
            alert('אירעה שגיאה בעת העדכון');
        }

    }

    function removeExistingImage(index: number) {
        removedFiles.current.push(images![index]);
        setImages(draft => {
            draft?.splice(index, 1);
        })
    }

    function imageChange(index: number, newFiles: FileList | File[] | null) {
        let original = files[index];
        filesUploader.delete((original).id, (original).link);
        if (!newFiles)
            return setFiles(draft => {
                draft.splice(index, 1);
            })
        if (newFiles.length) addNewImages(newFiles, index);
    }

    function addNewImages(newFiles: File[] | FileList | null, removeIndex?: number) {
        if (!newFiles) return;

        let resfiles: UploadedFile[] = [];
        for (let i = 0; i < newFiles.length; i++)
            resfiles.push(filesUploader.upload(newFiles[i]));


        setFiles(draft => {
            if (removeIndex !== undefined)
                draft.splice(removeIndex, 1);
            draft.push(...resfiles);
        });


    }


    return <div className={classes.container}>
        <div className={classes.images}>
            {images?.map((img, index) =>
                <GalleryImage key={img} imagePath={img} onChange={() => removeExistingImage(index)} />
            )}
            {files.map((file, index) => <GalleryImage key={file.id} imagePath={file.link} onChange={(files) => imageChange(index, files)} allowChange={true} />)}

            <GalleryImage onChange={addNewImages} />
        </div>
        <Button onClick={saveChanges} className={clsx(classes.button, classes.margin)}>
            שמור
        </Button>
    </div >

}



export const useStyles = makeStyles(theme => ({

    container: {
        direction: "ltr",
        fontSize: '1.2rem',
        margin: 'auto',
        width: '85vw',
        // maxWidth: '900px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        padding: '1vh 0 5vh',
        boxSizing: 'border-box',
        '&>button>span': {
            fontFamily: 'Assistant-Bold',
            fontSize: '1.2rem'
        }
    },
    images: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: "wrap"
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        borderRadius: '8px',
        padding: ' 0 1.2%',
        height: '2rem',
        fontSize: '1rem',
        margin: "auto",
        // margin: '2rem 0 1rem 0',
        fontFamily: "Assistant-Regular",
        width: "20%",
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
            border: "1px solid",
            borderColor: theme.palette.primary.main
        },
    },
    margin: {
        margin: "1rem auto",

    }
}))

