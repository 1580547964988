import { FormImage, FormFastInput, FormSelect, useForm } from "@hilma/forms"
import { useAsyncEffect } from "@hilma/tools";
import { Collapse, ListItem } from "@material-ui/core";
import axios from "axios";
import { FC, useState } from "react"
import { ProductCategories } from "./ProductCategories";

interface ProductThumbnailProps {
    id: "thumbnail",
    collabs: any[]

}
const ProductThumbnail: FC<ProductThumbnailProps> = ({ id, collabs }) => {
    const [open, setOpen] = useState(false);

    return <div className="box">
        <ListItem onClick={() => setOpen(v => !v)}>
            <h3>{"הפרויקט בעמוד הבית"}</h3>
            <img src="/images/thumbnail.png" height="130px" />
        </ListItem>
        <Collapse in={open} style={{ marginTop: "5%" }}>
            <FormImage id={`${id}.image`} />
            <ProductCategories />
            <FormFastInput id={`${id}.heShortDescription`} description="תיאור קצר בעברית" />
            <FormFastInput id={`${id}.enShortDescription`} description="תיאור קצר באנגלית" />
            <FormSelect id={`${id}.collaborate`} description={`גוף מפתח`} options={collabs} />
        </Collapse>
    </div>
}

export default ProductThumbnail;