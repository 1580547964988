import React, { useState } from "react";
import { Editor, EditorState, Modifier, RichUtils } from 'draft-js';
import 'draft-js/dist/Draft.css';
import { stateToHTML } from "draft-js-export-html";
import styles from '../styles/textEditor.module.scss';
import { useImmer } from "use-immer";
import clsx from 'clsx';
import { FC } from "react";
import { stateFromHTML } from "draft-js-import-html";
import { FormHelperText, useForm, useFormInputStyles, useTextFieldError } from "@hilma/forms";
import { InputLabel } from "@material-ui/core";
import { useEffect } from "react";
import { get as _get } from 'lodash';

enum Styles { underline = "underline", bold = "bold", italic = "italic" };
interface TextEditorProps {
    content?: string,
    placeholder?: string,
    id: string,
    description: string,
    onBlur?: () => void
}

export const TextEditor: FC<TextEditorProps> = ({ content, placeholder, id, description, onBlur: _onBlur }) => {
    const [editorState, setEditorState] = useState(
        () => content ? EditorState.createWithContent(stateFromHTML(content)) : EditorState.createEmpty(),
    );
    const [selected, setSelected] = useImmer<Record<Styles, boolean>>({ bold: false, italic: false, underline: false })

    const form = useForm();
    const classes = useFormInputStyles();

    useEffect(() => {
        let val = _get(form.values, id);
        if (val && val !== stateToHTML(editorState.getCurrentContent())) {//used to take initial value from form
            setEditorState(EditorState.createWithContent(stateFromHTML(val)));
        }
    }, [form.values])

    let error = useTextFieldError(id)
    function handleKeyCommand(command: string, editorState: EditorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            setEditorState(newState);
            if (command in Styles) {
                setSelected(draft => { draft[command as Styles] = !draft[command as Styles] })
            }
            return 'handled';
        }
        return 'not-handled';
    }

    const onBlur = () => {
        !form.touched[id] && form.setFieldTouched(id, true);
        const newFieldValue = stateToHTML(editorState.getCurrentContent());
        form.setFieldValue(id, newFieldValue);
        _onBlur && _onBlur();
    }
    function stylesChange(e: React.MouseEvent<HTMLButtonElement>, command: Styles) {
        e.preventDefault();
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);
            if (command in Styles) {
                setSelected(draft => { draft[command as Styles] = !draft[command as Styles] })
            }
        }
    }


    return <div >
        <div className={clsx(classes.containLables)}>
            <InputLabel htmlFor={id} className={clsx(classes.label, styles.label)}>{description}</InputLabel>
        </div>
        <div id="editor-container" className={clsx(styles.editorContainer, error ? styles.error : '')} >
            <div className={styles.buttonsContainer}>
                {Object.values(Styles).sort().map((_style: Styles) => {
                    return <button key={_style} className={clsx(styles.button, selected[_style] && styles.pressed)} onMouseDown={(e) => { stylesChange(e, _style) }}>
                        <img height="17px" width="17px" src={`/admin-icons/${_style}.svg`} />
                    </button>
                })}
            </div>

            <Editor editorState={editorState}
                onChange={(content) => {
                    setEditorState(content);
                }}
                placeholder={placeholder || "Enter text here"}
                handleKeyCommand={handleKeyCommand}
                onBlur={onBlur}
            />

        </div>
        <FormHelperText error text={error} />
    </div>;
}