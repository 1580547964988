export const backgroundGray = '#F2F2F2';
export const subTopBarGray = '#EAEAEA';
export const boxShadow = '#00000029';
export const forestGreen = '#3E504C';
export const darkYellow = '#FFAF00';
export const lightGray = '#f5f5f5';
export const darkGray = '#707070';
export const skyBlue = '#42BFDD';
export const yellow = '#FFB71A';
export const purple = '#711568';
export const white = '#F2F2F2';
export const lightWhite = '#FFFFFF';
export const error = '#FF2A2A';
export const teal = '#35788C';
export const skyBlue40 = skyBlue + '40';
