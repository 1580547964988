import { Option, useForm, useFormInputStyles, FormHelperText, useTextFieldError } from '@hilma/forms';
import makeStyles from "@material-ui/core/styles/makeStyles";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButton from './RadioButton';
import FormLabel from "@material-ui/core/FormLabel";
import RadioGroup from '@material-ui/core/RadioGroup';
import clsx from "clsx";

interface RadioGroupInputProps {
    id: string;
    description: string;
    options: Option[];
    disabled?: boolean;
}

const RadioGroupInput: React.FC<RadioGroupInputProps> = ({ id, description, options, disabled }) => {

    const form = useForm();
    const classes = useStyles();
    const formInputClasses = useFormInputStyles();

    const fieldError = useTextFieldError(id);


    const handleChange = (_: React.ChangeEvent<HTMLInputElement>, value: string) => {
        form.setFieldValue(id, value);
    };

    return (
        <div className="input-container">

            <FormControl component="fieldset" className={`radio-buttons-container ${classes.container}`}>

                {description ?
                    <div className={clsx(formInputClasses.containLables)}>
                        <FormLabel htmlFor={id} className={clsx(formInputClasses.label, 'label-x', classes.label)}>{description}</FormLabel>
                    </div> : null}

                <div className="" style={{ width: '35vw' }}>

                    <RadioGroup aria-disabled={disabled || false} aria-label={description} name={description} value={form.values[id]} onChange={handleChange}>
                        {options.map(option =>
                            <FormControlLabel
                                className={classes.radioLabel}
                                checked={option.id === form.values[id]}
                                key={option.id}
                                value={option.id}
                                control={<RadioButton />}
                                label={option.value}
                            />
                        )}
                    </RadioGroup>

                    <FormHelperText text={fieldError} error />
                </div>

            </FormControl>
        </div>
    );
}

export default RadioGroupInput;

const useStyles = makeStyles(theme => ({
    container: {
        color: theme.palette.primary.main,
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        marginBottom: 0,
        gap: '2vw'
    },
    label: {
        margin: '0 0 1vh',
        alignSelf: "flex-start",
        paddingTop: "1vh"
    },
    radioLabel: {
        marginRight: 0,
        fontSize: "17px"
    }
}));