import React, { useMemo, useState } from "react"
import { useForm, FormFastInput, FormImage } from "@hilma/forms"
import { Collapse, InputLabel, ListItem } from "@material-ui/core";
import { get as _get } from "lodash";
import styles from '../styles/singleFeature.module.scss';
interface SingleRecommenderProps {
    id: string,
    deleteRecommender: () => void
}

export const SingleRecommender: React.FC<SingleRecommenderProps> = React.memo(({ id, deleteRecommender }) => {
    const [open, setOpen] = useState(false);
    const form = useForm();

    const recommender = useMemo(() => _get(form.values, id), [form.values.recommenders]);

    return <div className={styles.edit_feature_container}>
        <ListItem onClick={() => setOpen(v => !v)}>
            {recommender.image && <img src={recommender.image} height="50px" width="50px" />}
            <InputLabel>
                {recommender.heTitle || "חוות דעת חדשה"}
            </InputLabel>
            <img src="/admin-icons/x.svg" height="15px" width="15px" className={styles.removeRow} onClick={deleteRecommender} />

        </ListItem>
        <Collapse in={open}>

            <FormImage id={`${id}.image`} />
            <FormFastInput id={`${id}.heTitle`} description="שם הממליץ ותואר בעברית" />
            <FormFastInput id={`${id}.heText`} description="תוכן ההמלצה בעברית" multiline={true} minRows={3} />
            <FormFastInput id={`${id}.enTitle`} description="שם הממליץ ותואר באנגלית" />
            <FormFastInput id={`${id}.enText`} description="תוכן ההמלצה באנגלית" multiline={true} minRows={3} />
        </Collapse>
    </div>
})