import { FormFastInput, GridifyChildren } from "@hilma/forms";
import { Collapse, ListItem } from "@material-ui/core";
import { FC, useState } from "react";

interface OrgPurchaseOptionsProps {
    id: "organizationPurchaseOptions"
}

export const OrgPurchaseOptions: FC<OrgPurchaseOptionsProps> = ({ id }) => {
    const [open, setOpen] = useState(false);

    let types = [{ type: "private", text: "אדם פרטי" }, { type: "institutional", text: "ארגון קטן" }, { type: "customized", text: "מוסד- צרו קשר" }];
    return <div className="box">
        <ListItem onClick={() => setOpen(v => !v)}>
            <h3>{"אפשרויות רכישה ומחירים"}</h3>
            <img src={`/images/purchaseOptions.png`} height="130px" />
        </ListItem>
        <Collapse in={open} style={{ marginTop: "5%" }}>
            {types.map(item => <div key={item.type}>
                <p>{item.text}</p>
                <GridifyChildren>
                    <FormFastInput id={`${id}.${item.type}.heTitle`} description={`${item.text} - כותרת בעברית`} />
                    <FormFastInput id={`${id}.${item.type}.enTitle`} description={`${item.text} - כותרת באנגלית`} />
                </GridifyChildren>
                <GridifyChildren>
                    <FormFastInput id={`${id}.${item.type}.hePrice`} description={`${item.text} - מחיר בעברית`} />
                    <FormFastInput id={`${id}.${item.type}.enPrice`} description={`${item.text} - מחיר באנגלית`} />
                </GridifyChildren>
                <FormFastInput id={`${id}.${item.type}.heText`} description={`${item.text} - טקסט בעברית`} multiline={true} minRows={2} />
                <FormFastInput id={`${id}.${item.type}.enText`} description={`${item.text} - טקסט באנגלית`} multiline={true} minRows={2} />
            </div>)}

        </Collapse>
    </div>
}