import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles, Tooltip, Zoom } from "@material-ui/core"
import { createRules, useForm, FormFastInput, useAlert, FormProvider, FormImage, Localize } from "@hilma/forms"
import React, { FC, useEffect, useState } from "react"
import { provide } from "@hilma/tools"
import * as Yup from 'yup';
import axios from "axios"
import { Collaborators } from "../pages/Collaborators";
import { Pageview, Close } from "@material-ui/icons";
import clsx from "clsx";
import { useStyles } from "./EditProduct/EditProductDetails";

interface CreateCollaborationPopupProps {
    open: boolean,
    close: () => void,
    save?: (collab: Collaboration, update?: boolean) => void
    collaboration?: Collaboration
}

export interface Collaboration {
    _id: string,
    heName?: string,
    enName?: string,
    image?: string,
    ImageFile?: File
}

export const createCollaborationRules = createRules({
    heName: { required: true },
    // enName: { required: true },
    image: { required: false, accept: ['image/*'] }
})

export const createCollaboration_initialValues = {
    heName: "",
    enName: "",
    image: null
}

const CreateCollaborationSchema = Yup.object().shape({
    heName: Yup.string()
        .min(2, 'Too Short!')
        .max(100, 'Too Long!')
        .required('Required'),
    // enName: Yup.string()
    //     .min(2, 'Too Short!')
    //     .max(100, 'Too Long!')
    //     .required('Required')
});


export const CreateCollaborationPopup: FC<CreateCollaborationPopupProps> = ({ open, close, collaboration, save: propsSave }: CreateCollaborationPopupProps) => {
    const [openPreview, setOpenPreview] = useState(false);
    const [loading, setLoading] = useState(true);
    const classes = useImageInputStyles();
    const buttonClasses = useStyles();

    const alert = useAlert();
    const form = useForm()

    useEffect(() => {
        form.resetForm();
        if (collaboration) {
            form.setValues({
                enName: collaboration.enName,
                heName: collaboration.heName,
                image: collaboration.image,
                ImageFile: collaboration.ImageFile
            })
        } else form.setValues({
            enName: "",
            heName: "",
            image: null,
            ImageFile: ""
        });
        setLoading(false);
    }, [collaboration])

    const save = async () => {
        if (loading) return;
        setLoading(true)
        if (!form.isValid) {
            console.error("ERRORS", form.errors, form.isValid)
            alert("ישנן שגיאות, הטופס לא נשמר");
            setLoading(false);
            return;
        }
        let values = JSON.parse(JSON.stringify(form.values));
        if (form.values.imageFile instanceof File) {
            values["Image"] = new File([form.values.imageFile], form.values.imageFile.name, { type: form.values.imageFile.type })
            delete values.imageFile;
            delete values.image;
        }
        const body = {
            files: values["Image"] ? true : false,
            id: collaboration?._id || "",
            ...values
        }
        let newCollab: Collaboration = body;
        if (body.enName === collaboration?.enName) delete body.enName;
        if (body.heName === collaboration?.heName) delete body.heName;
        if (!collaboration) delete body.id
        try {
            const { data } = await axios.post<Localize<Collaboration>>("/api/collaborate/add-edit-collaborator", body);
            newCollab = collaboration ? newCollab : data;
            if (typeof propsSave === 'function') propsSave(newCollab, !!collaboration);
            alert("יצירת חברת המערכת הצליחה", "success");
            cleanClose();
        } catch (err) {
            alert("יצירת חברת המערכת נכשלה")
        }
        setLoading(false);
    }

    const cleanClose = () => {
        if (loading) return;
        form.resetForm()
        close()
    }

    return (
        <Dialog
            open={open}
            onClose={close} >
            <DialogTitle>
                פרטי גוף מפתח
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <div style={{ display: 'flex', gap: "2rem" }}>
                        <FormImage classes={{ root: classes.root }} id="image" />
                        <span>
                            <FormFastInput disabled={loading} id="heName" description={"שם חברת המערכת בעברית"} />
                            <FormFastInput disabled={loading} id="enName" description={"שם חברת המערכת באנגלית"} />

                            <Button
                                onClick={() => setOpenPreview(prev => !prev)}
                                disabled={loading}
                                className={clsx(buttonClasses.button, buttonClasses.margin, buttonClasses.addTutorial)}>
                                <Pageview className='preview' />
                                תצוגה מקדימה
                            </Button>
                        </span>
                    </div>
                </DialogContentText>
            </DialogContent>

            <Dialog open={openPreview} onClose={() => setOpenPreview(prev => !prev)} >

                <div className={"thumbnail-container "}>
                    <div className="product-img">
                        <Close className='close' onClick={() => setOpenPreview(prev => !prev)} />
                        <div className="hilma-tag">
                            {collaboration?.['image'] || form.values?.image ?
                                <img src={collaboration?.['image'] || form.values?.image || ""} className="hilma-logo" /> :
                                <h3>{collaboration?.['enName'] || form.values.enName || "--"}</h3>
                            }
                        </div>
                        <div className='img'></div>
                    </div>
                    <div className="product-info">
                        <div className="texts">

                            <div className="name">
                                Name
                            </div>
                            <div className="description">
                                Description
                            </div>
                        </div>
                        <div className="categories">
                            Categories
                        </div>
                    </div>
                </div>


            </Dialog>


            <DialogActions>
                <Button onClick={cleanClose} disabled={form.isSubmitting || loading}>
                    ביטול
                </Button>
                <Button onClick={save} disabled={form.isSubmitting || loading}>
                    שמירה
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default provide([FormProvider, {
    initialValues: createCollaboration_initialValues,
    rules: createCollaborationRules,
    validationSchema: CreateCollaborationSchema,
    validateOnBlur: true
}])(CreateCollaborationPopup);


const useImageInputStyles = makeStyles(theme => ({
    root: {
        height: "19rem",
    }
}))