import { FormInput } from "@hilma/forms";
import { FC } from "react";

interface ProductVideoProps {
    id: "video"
}
export const ProductVideo: FC<ProductVideoProps> = ({ id }) => {
    return <div className="box">
        <FormInput id={id} description="קישור לסרטון על המוצר" />
    </div>
}