import React, { useState } from "react"
import { useLogin } from "@hilma/auth"
import { FormInput, FormProvider, useForm } from "@hilma/forms"
import { provide } from "@hilma/tools"
import { Button } from "@material-ui/core"
import { useNavigate } from "react-router-dom"
import './styles/Login.scss';
interface LoginProps { }

const Login: React.FC<LoginProps> = ({ }) => {
    const form = useForm();
    const _login = useLogin();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    async function login() {
        form.handleSubmit();
        if (!form.isValid) return;

        const res = await _login('/api/101/admin/login', form.values)
        if (res.success) navigate('/products');
        else { setError('שם המשתמש או הסיסמה שגויים') }
    }


    return <div id="login-container"  >
        <h3>כניסה למערכת</h3>
        <FormInput id='username' description='שם משתמש' />
        <FormInput id='password' description='סיסמה' type="password"
            onKeyPress={(e) => {
                if (e.key === 'Enter') {
                    login();
                }
            }} />
        <Button
            onClick={login}
            variant="contained"
            color="primary"
        >התחבר</Button>
        <p>{error}</p>
    </div>
}

export default provide(
    [FormProvider, {
        initialValues: { username: '', password: '' },
        rules: {
            username: { required: true },
            password: { required: true }
        }
    }]
)(Login);


