import * as Yup from 'yup';
import { StripType } from '../enums/strip-type.enum';
import { YupTextValidation } from '../functions';



const YupOrganizationPurchase = Yup.object().shape({
    heTitle: YupTextValidation(2, 50, 'שם בעברית', false),
    // enTitle: YupTextValidation(2, 50, 'שם באנגלית', false),
    hePrice: YupTextValidation(2, 150, 'מחיר בעברית', false),
    // enPrice: YupTextValidation(2, 150, 'מחיר באנגלית', false),
    heText: YupTextValidation(2, 250, 'טקסט בעברית', false),
    // enText: YupTextValidation(2, 250, 'טקסט באנגלית', false),
});


export const YupProductSchema = Yup.object().shape({
    enName: YupTextValidation(2, 50, 'שם באנגלית'),
    heName: YupTextValidation(2, 50, 'שם בעברית'),
    thumbnail: Yup.object().shape({
        heShortDescription: Yup.string().min(5, 'תיאור קצר צריך להיות לפחות חמישה תווים').max(100).required('שדה זה הינו חובה'),
        enShortDescription: Yup.string().min(5).max(100, 'תיאור קצר צריך להיות עד מאה תווים').required('שדה זה הינו חובה'),
    }),
    infoStrip: Yup.object().shape({
        enTitle: YupTextValidation(2, 150, 'כותרת באנגלית'),
        heTitle: YupTextValidation(2, 150, 'כותרת בעברית'),
        enText: YupTextValidation(2, 1500, 'טקסט באנגלית'),
        heText: YupTextValidation(2, 1500, 'טקסט בעברית'),
        titleColor: Yup.string().matches(/^#[0-9A-Fa-f]{6}$/, 'אנא בחר צבע מתוך בוחר הצבעים או ספק צבע בפורמט HEX'),
        // imageFile: YupFileSchema(['image/jpg', 'image/png', 'image/jpeg'])

    }),
    purchaseText: Yup.object().shape({
        // enTitle: YupTextValidation(2, 50, 'כותרת באנגלית'),
        heTitle: YupTextValidation(2, 50, 'כותרת בעברית'),

        // enText: YupTextValidation(2, 300, 'טקסט באנגלית'),
        heText: YupTextValidation(2, 300, 'טקסט בעברית'),

    }),
    recommenders: Yup.array().of(Yup.object().shape({
        // enTitle: YupTextValidation(2, 25, 'שם באנגלית'),
        heTitle: YupTextValidation(2, 25, 'שם בעברית'),
        // enText: YupTextValidation(2, 250, 'טקסט באנגלית'),
        heText: YupTextValidation(2, 250, 'טקסט בעברית'),


    })),
    organizationPurchaseOptions: Yup.object().shape({
        private: YupOrganizationPurchase,
        institutional: YupOrganizationPurchase,
        customized: YupOrganizationPurchase,
    }),
    download: Yup.object().shape({
        web: Yup.string().url(),
        android: Yup.string().url(),
        ios: Yup.string().url(),
        leaveDetailsIframe: Yup.string().url()
    }),
    video: Yup.string().url('שדה זה אינו תקין- אנא הכנס קישור לסרטון'),
    order: Yup.array().of(Yup.string()).test({ test: () => true })

});
