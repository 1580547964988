import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import { FC } from "react"


interface ConfirmPopupProps {
    open: boolean;
    handleClose: (value: boolean) => void;
    title?: string;
    content: string;
    yesText?: string;
    noText?: string
}

export const ConfirmPopup: FC<ConfirmPopupProps> = ({ open, handleClose, content, title, yesText, noText }) => {

    return <Dialog
        open={open}
        onClose={() => handleClose(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {content}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => handleClose(false)}>{noText || "לא"}</Button>
            <Button onClick={() => handleClose(true)} autoFocus>
                {yesText || "כן"}
            </Button>
        </DialogActions>
    </Dialog>
}