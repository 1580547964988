import React from "react"
import { useMemo } from "react"
import { Localize } from "@hilma/forms";

import { ReactComponent as Zoom } from './../icons/camera.svg';
import { ReactComponent as Location } from './../icons/location.svg';
import { Tutorial as TutorialType } from "../common/types"
import './styles/Tutorial.scss'
interface TutorialProps {
    tutorial: Localize<TutorialType>,
    name?: string,
    onEditClick: () => void
}
export const Tutorial: React.FC<TutorialProps> = ({ tutorial, name = "", onEditClick }) => {


    const date = useMemo(() => {
        if (!tutorial.date) return "";
        return `${new Date(tutorial.date).toLocaleDateString()}   ${tutorial.timeRange}`
    }, [tutorial?.date])

    return <div className="tutorial">
        <div className="heading">
            <div className="tut-product-name">{name}</div>
            {tutorial.heLocation ? <Location /> : <div className="camera"><Zoom /><div className="triangle" /></div>}
        </div>
        <div className="tut-title">{tutorial.heTitle}</div>
        <div className="tut-subtitle">{tutorial.heSubtitle}</div>
        <div className="details-n-signup-btn">
            <div className="details">
                <p className="when">{date}</p>
                <p className="where">{tutorial.heLocation!!}</p>
            </div>

            <button className="sign-up" onClick={onEditClick}>עריכת פרטי הדרכה</button>
        </div>
    </div>
}