import { useEffect, useState } from "react";
import { FormImage, GridifyChildren, useForm, FormFastInput } from "@hilma/forms";
import { Collapse, ListItem } from "@material-ui/core";
import { TextEditor } from "./TextEditor";
import { get as _get } from 'lodash';
interface ProdInfoStripProps {
    id: "purchaseText" | "infoStrip";

}
const ProdInfoStrip: React.FC<ProdInfoStripProps> = ({ id }) => {
    const [open, setOpen] = useState(false);
    const form = useForm();

    useEffect(() => {
        if (_get(form.errors, id)) {
            setOpen(true);
        }
    }, [form.errors[id]])

    return (
        <div className="box">
            <ListItem onClick={() => setOpen(v => !v)}>
                <h3>{id === "infoStrip" ? "כותרת ומידע בתחילת העמוד" : "איזור רכישת ארגונים"}</h3>
                <img src={`/images/${id}.png`} height="130px" />
            </ListItem>
            <Collapse in={open} style={{ marginTop: "5%" }}>
                <FormImage id={`${id}.image`} />
                <GridifyChildren>
                    <FormFastInput id={`${id}.heTitle`} description={"כותרת בעברית"} />
                    <FormFastInput id={`${id}.enTitle`} description={"כותרת באנגלית"} />
                </GridifyChildren>
                <TextEditor id={`${id}.heText`} content={_get(form.values, `${id}.heText`) || ""} description={"הכנס תיאור לפרויקט בעברית"} placeholder="הקלד כאן, אין צורך להכניס את שם הפרויקט" />
                <TextEditor id={`${id}.enText`} content={_get(form.values, `${id}.enText`) || ""} description={"הכנס תיאור לפרויקט באנגלית"} placeholder="הקלד כאן, אין צורך להכניס את שם הפרויקט" />
            </Collapse>
        </div>
    )
}
export default ProdInfoStrip;
