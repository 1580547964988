import { FormInput, FormProvider, useAlert, useForm, useUpdateOnSubmit } from "@hilma/forms"
import { provide } from "@hilma/tools"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@material-ui/core"
import { FC, MouseEventHandler, useEffect } from "react"
import { Close } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles"
import { Category } from "../pages/Categories"
import axios from "axios";
import * as Yup from 'yup';

interface CategoryForm {
    open: boolean;
    category?: Category;
    handleClose: (value: boolean) => void;
}

const schema = Yup.object().shape({
    heName: Yup
        .string()
        .required("שדה חובה")
        .max(20, 'ניתן להכניס עד 20 תווים'),

    enName: Yup
        .string()
        .required("שדה חובה")
        .max(20, 'ניתן להכניס עד 20 תווים'),
});

const CategoryForm: FC<CategoryForm> = ({ open, category, handleClose }: CategoryForm) => {
    const form = useForm();
    const classes = useStyle();
    const alert = useAlert();


    useEffect(() => {
        if (!open) form.resetForm();
        else category && form.setValues(category);
    }, [open])

    const close = () => handleClose(false);

    useUpdateOnSubmit(() => {
        (async () => {
            try {
                await axios.post('/api/categories/add-update-category', form.values)
                handleClose(true);
                alert(`הקטגוריה ${category ? "התווספה" : "התעדכנה"} בהצלחה`, 'success');
            } catch (error) {
                alert('אירעה שגיאה, נסו שנית מאוחר יותר')
            }
        })()
    })

    return (
        <Dialog classes={{ paper: classes.container }} open={open} onClose={close}>
            <DialogTitle id="alert-dialog-title">
                <IconButton className={classes.close} onClick={close}>
                    <Close />
                </IconButton>
                {category ? 'עריכה' : 'הוספה'}
            </DialogTitle>

            <DialogContent>
                <FormInput id="heName" description="שם קטגוריה" />
                <FormInput id="enName" description="שם באנגלית" />
            </DialogContent>

            <DialogActions>
                <Button onClick={form.handleSubmit as unknown as MouseEventHandler<HTMLButtonElement>}>
                    שמירה
                </Button>
            </DialogActions>
        </Dialog>
    )
}


export default provide([FormProvider, {
    initialValues: { heName: '', enName: '' },
    rules: {},
    validationSchema: schema,
    validateOnBlur: true,
}])(CategoryForm)



const useStyle = makeStyles({
    container: {
        width: '40%'
    },

    close: {
        paddingLeft: 0
    }
})
