import { Button, CircularProgress } from '@material-ui/core'
import { useStyles } from "../components/EditProduct/EditProductDetails";
import clsx from 'clsx';
import React, { useState } from 'react'
import CreateCollaborationPopup, { Collaboration } from '../components/CreateCollaborationPopup';
import { useAsyncEffect } from '@hilma/tools';
import { Delete, Edit } from '@material-ui/icons';
import axios from 'axios';
import { useAlert } from '@hilma/forms';
import { ConfirmPopup } from '../components/ConfirmPopup';

export const Collaborators = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [openConfirm, setOpenConfirm] = useState<string | undefined>(undefined);
    const [collaborators, setCollaborators] = useState<Collaboration[] | undefined>();
    const [chosenCollaborator, setChosenCollaborator] = useState<Collaboration | undefined>(undefined);
    const [loading, setLoading] = useState(true);
    const classes = useStyles();
    const alert = useAlert();

    useAsyncEffect(async () => {
        try {
            const { data } = await axios.get('/api/collaborate/get-collaborators');
            if (data) setCollaborators(data);
        } catch (err) {
            console.log('err: ', err);
            alert('שגיאה בהבאת המידע')
        }
        setLoading(false);
    }, [])

    const deleteCollaborator = async (id: string) => {
        try {
            await axios.post('/api/collaborate/delete-collaborator', { id })
            setCollaborators(prev => prev?.filter(val => val._id != id));
            alert('המחיקה בוצעה בהצלחה', 'success');
        } catch (err) {
            alert('ארעה שגיאה במחיקה')
        }
        setOpenConfirm(undefined);
    }

    const saveCollab = (collab: Collaboration & { id?: string }, update?: boolean) => {
        if (update) {
            setCollaborators(prev => prev?.map(val => val._id === collab._id || val._id === collab.id ? { ...val, ...collab } : val))
        } else {
            setCollaborators(prev => ([...(prev || []), collab]));
        }

    }

    const closePopup = () => {
        setOpen(prev => !prev);
        setChosenCollaborator(undefined);
    }

    const openPopup = (coll?: Collaboration) => {
        setChosenCollaborator(coll);
        setOpen(prev => !prev);
    }

    return (
        <div className="admin-collaborators-page">
            <span className="top-container">
                <h3>גוף מפתח</h3>
                <Button
                    onClick={() => openPopup()}
                    className={clsx(classes.button, classes.margin, classes.addTutorial)}
                >
                    <h3>+ הוספה<br /></h3>
                </Button>
            </span>
            <div className="collaborators-container">
                {loading ? <CircularProgress className='loading' /> : !collaborators ? <h3>Error</h3> : collaborators?.length === 0 ? <h3>No Collaborators</h3> :
                    collaborators?.map?.(item => <span className="collaborate" key={item.enName}>
                        <Delete onClick={() => setOpenConfirm(item._id)} className="delete" />
                        <Edit onClick={() => openPopup(item)} className='edit' />
                        <div className='img-container'>
                            <img src={item.image} />

                        </div>
                        <h4>{item.heName || "--"}</h4>
                    </span>)}

                <CreateCollaborationPopup
                    open={open}
                    save={saveCollab}
                    close={closePopup}
                    collaboration={chosenCollaborator}
                />

                <ConfirmPopup
                    open={!!openConfirm}
                    handleClose={() => deleteCollaborator(openConfirm || "")}
                    content="את/ה בטוח/ה שברצונך למחוק הגוף המפתח הזה?" />
            </div>
        </div>
    )
}
