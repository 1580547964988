import { useEffect, useState } from "react"
import { useAsyncEffect } from "@hilma/tools"
import axios from "axios"
import { Button, Card, Chip, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Delete, Edit } from "@material-ui/icons"
import { ConfirmPopup } from "../components/ConfirmPopup"
import { useStyles as useStylesButton } from "../components/EditProduct/EditProductDetails";
import clsx from "clsx"
import { useAlert } from "@hilma/forms"
import CategoryForm from "../components/CategoryForm"

export interface Category {
	_id: string,
	heName: string,
	enName: string
}

const Categories = () => {
	const [categories, setCategories] = useState<Category[] | null>(null);
	const [openPopup, setOpenPopup] = useState<'delete' | 'category-form' | null>(null);
	const [categoryId, setCategoryId] = useState<string>('');
	const classes = useStyles();
	const buttonClasses = useStylesButton();
	const alert = useAlert();

	useAsyncEffect(async () => {
		fetch();
	}, [])

	const fetch = async () => {
		const { data } = await axios.get<Category[]>('/api/categories');
		setCategories(data);
	}

	const addCategory = () => openPopupWithId('category-form');

	const openPopupWithId = (type: 'delete' | 'category-form', id?: string) => {
		setOpenPopup(type);
		id && setCategoryId(id);
	}

	const onDelete = async () => {
		try {
			await axios.delete(`/api/categories/delete-category/${categoryId}`)
			alert('הקטגוריה נמחקה בהצלחה', 'success');
			setCategoryId('');
		} catch (error) {
			alert('המחיקה נכשלה, נסו שנית מאוחר יותר')
		}
	}

	const closePopup = (confirm: boolean) => {
		if (confirm && openPopup === 'delete') onDelete();
		setOpenPopup(null);
		if (confirm) fetch();
	}

	return (
		<>
			<div className={`categories-page ${classes.page}`}>
				<div className={clsx(classes.titleWithButton, classes.container)}>
					<h2 className="title">קטגוריות</h2>
					<Button onClick={addCategory} className={clsx(buttonClasses.button, classes.button)}>
						+ הוספה
					</Button>
				</div>
				<div className={`categories-container ${classes.container}`}>
					<TableContainer component={Paper}>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>שם קטגוריה</TableCell>
									<TableCell>שם באנגלית</TableCell>
									<TableCell></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{categories?.map(category => (
									<TableRow>
										<TableCell>{category.heName}</TableCell>
										<TableCell>{category.enName}</TableCell>
										<TableCell className={classes.tableIcon}>
											<IconButton className={classes.icon} onClick={() => openPopupWithId('category-form', category._id)}><Edit /></IconButton>
											<IconButton className={classes.icon} onClick={() => openPopupWithId('delete', category._id)}><Delete /></IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>
				</div>
			</div>
			<ConfirmPopup open={openPopup === 'delete'} title="מחיקת קטגוריה" content="האם ברצונך למחוק את הקטגוריה?" handleClose={closePopup} />
			<CategoryForm category={categories?.find(cat => cat._id === categoryId)} open={openPopup === 'category-form'} handleClose={closePopup} />

		</>
	)
}

export default Categories


const useStyles = makeStyles({
	page: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},

	container: {
		width: '60%',
	},

	category: {
		padding: '3vh 3vw',
		width: '100%',
		marginBottom: '2vh',
		backgroundColor: '#f4f4f4',
		display: 'flex',
		alignItems: 'center'
	},

	titleWithButton: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	button: {
		marginRight: 0,
		width: '8vw',
		whiteSpace: 'nowrap'
	},
	tableIcon: {
		width: '11vw'
	},
	icon: {
		width: '5vw'
	}
})
