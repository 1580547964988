import React, { useState } from "react"
import { FormProvider, Localize, useAlert, useForm } from "@hilma/forms"
import { provide, useAsyncEffect } from "@hilma/tools"
import { Box, Tab, } from "@material-ui/core"
import { TabContext, TabList, TabPanel } from "@material-ui/lab"
import axios from "axios"
import { useNavigate, useParams } from "react-router-dom"
import { Product } from "../../common/types"
import EditProductDetails, { productRules, product_initialValues } from "./EditProductDetails"
import { ProductImageGallery } from "./ProductImageGallery"
import PurchasesTypes from "./PurchasesTypes"
import Loading from "../Loading";
import { YupProductSchema } from "../../common/consts/YupProductSchema"

export const EditProduct: React.FC = ({ }) => {
    const [value, setValue] = useState('1');
    const [collabs, setCollabs] = useState([]);
    const { name } = useParams<"name">()
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const alert = useAlert();
    const form = useForm();

    const handleTabChange = (event: any, newValue: string) => {
        setValue(newValue);
    };

    function updateImages(images: string[]) {
        form.setFieldValue('imageGallery', images, false);
    }

    useAsyncEffect(async () => {
        let { data } = await axios.get<Localize<Product> | any>('/api/product/get-edit-product-data', { params: { name } });
        // console.log('data: ', data);
        if (Object.entries(data.product).length === 0 || !data.product) {
            alert("הפרוייקט לא קיים");
            navigate("/products")
            return;
        }
        data.product.removedImages = [];
        form.setValues({ ...product_initialValues, ...data.product });
        console.log('data?.collabs: ', data?.collabs);
        setCollabs(data?.collabs?.map?.((item: any) => ({ id: item._id, value: item.heName })));
        // form.setTouched({});
        setLoading(false);
    }, [])

    return (
        <Box sx={{ width: '100%', }}>
            {form.isSubmitting && <Loading width="100%" fixed={true} />}
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                        <Tab label="עריכת פרטים" value="1" />
                        <Tab label="גלריה" value="2" />
                        <Tab label="סוגי הרכישות" value="3" />
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <EditProductDetails name={name!} collabs={collabs} loading={loading} />
                </TabPanel>
                <TabPanel value="2"><ProductImageGallery images={form.values.imageGallery} id={form.values?._id!} updateImages={updateImages} /></TabPanel>
                <TabPanel value="3">
                    <PurchasesTypes name={name} />
                </TabPanel>
            </TabContext>
        </Box>
    );
}
export default provide([FormProvider, {
    initialValues: product_initialValues,
    rules: productRules, validationSchema: YupProductSchema,
    validateOnBlur: true
}])(EditProduct);