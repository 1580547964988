import React from "react";
import { useNavigate } from "react-router-dom";
import './styles/NotFound.scss';

export const NotFound: React.FC<{}> = () => {
    const navigate = useNavigate();

    const backToHome = () => navigate('/');

    return <div className='not-found'>
        <img src='/images/error-404.png' alt='NOT_FOUND' />
        <h2>שגיאה! העמוד אותו ביקשת לא קיים</h2>
        <p>לחזרה לעמוד הראשי לחץ <span onClick={backToHome} className='link'>כאן</span></p>
    </div>
}
