import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core"
import React, { FC } from "react"
import * as Yup from 'yup';
import axios from "axios"
import { useNavigate } from "react-router-dom";

interface GenericInfoPopupProps {
    close: () => void,
    approve: () => void,
    infoText: string,
    title: string,
    open: boolean
}

export const GenericInfoPopup: FC<GenericInfoPopupProps> = ({ open, title, infoText, approve, close }: GenericInfoPopupProps) => {
    return (
        <Dialog
            open={open}
            onClose={close} >
            <DialogTitle>
                {title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {infoText}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={approve}>
                    אישור
                </Button>
            </DialogActions>
        </Dialog>
    )
}
