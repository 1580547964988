
import { useState, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Fade from '@material-ui/core/Fade';
import { ClassNameMap } from '@material-ui/styles/withStyles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { FormImageStylesProps, useDragAndDrop } from "@hilma/forms";
import clsx from 'clsx';
import { Button, makeStyles } from '@material-ui/core';

const imageAccept = ['image/jpg', 'image/png', 'image/jpeg'];

interface GalleryImageProps {
    noDelete?: boolean;
    imagePath?: string;
    classes?: Partial<ClassNameMap<'root' | 'badge' | 'fade' | 'avatar' | 'blank' | 'helperText' | 'error'>>;
    onChange: (arg: FileList | File[] | null) => void,
    allowChange?: boolean
}

const GalleryImage: React.FC<GalleryImageProps> = ({ noDelete = false, classes: cl = {}, onChange, imagePath, allowChange = false }) => {
    const [isHovering, setIsHovering] = useState(false);

    const fileInputRef = useRef<HTMLInputElement>(null);
    const classes = useImageStyles({ error: false });


    const updateImage = async (files: FileList | File[] | null) => {
        files = files ?? [];
        if (!files.length) return;
        let image;
        for (let i = 0; i < files.length; i++) {
            image = files[i];
            if (!image) return onChange(null);
            if (!imageAccept.includes(image.type)) { }
        }
        onChange(files);
    }

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateImage(event.target.files);
    }

    const { isDragging, ...dragDropProps } = useDragAndDrop(updateImage);

    const handleHovering = (boolean: boolean) => () => setIsHovering(boolean);

    const handleInputOpen = () => {
        fileInputRef.current?.click();
    }

    const handleDelete = () => {
        onChange(null);
    }

    return (
        <div className={clsx(classes.root, isDragging && classes.dragging, cl.root)} {...dragDropProps}>
            <div className={classes.rootInner}>
                {imagePath ? (
                    <Badge
                        overlap="rectangle"
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        classes={{
                            root: cl.badge,
                            badge: classes.badge
                        }}
                        badgeContent={(
                            <Fade in={isHovering}>
                                <div
                                    onMouseEnter={handleHovering(true)}
                                    onMouseLeave={handleHovering(false)}
                                    className={cl.badge}
                                >
                                    {allowChange && <IconButton color="primary" onClick={handleInputOpen}>
                                        <EditIcon />
                                    </IconButton>}
                                    {!noDelete && (
                                        <IconButton color="primary" onClick={handleDelete}>
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                </div>
                            </Fade>
                        )}
                    >
                        <Avatar
                            alt=""
                            src={imagePath}
                            variant="square"
                            className={clsx(classes.container, classes.avatar, cl.avatar)}
                            onMouseEnter={handleHovering(true)}
                            onMouseLeave={handleHovering(false)}
                        />
                    </Badge>
                ) : (
                    <div className={clsx(classes.container, classes.blank, cl.blank)}>
                        <PictureSvg className={classes.blankSvg} />
                        <span className={clsx(classes.blankSpan, classes.text)}>אפשר לגרור לכאן</span>
                        <Button
                            color="primary"
                            variant="outlined"
                            onClick={handleInputOpen}
                            classes={{
                                root: classes.blankButton,
                                label: clsx(classes.text, classes.blankButtonLabel)
                            }}
                        >
                            {/* {fi.addImage} */}
                            בחר תמונות להעלות לגלריה
                        </Button>
                    </div>
                )}
                <input
                    ref={fileInputRef}
                    type="file"
                    className={classes.input}
                    onChange={handleInputChange}
                    accept={imageAccept.join('|')}
                    multiple={true}
                />
                {/* <FormHelperText className={cl.helperText} text={helperText} /> */}
                {/* <FormHelperText className={cl.error} text={fileError} error /> */}
            </div>
        </div>
    );
}

export default GalleryImage;
const useImageStyles = makeStyles(theme => ({
    root: {
        transition: '0.2s',
        display: 'flex',
        justifyContent: 'center',
        margin: "1%",

    },

    rootInner: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },

    dragging: {
        opacity: 0.8,
        transform: 'scale(0.98)',
        '-webkit-transform': 'scale(0.98)',
        '-moz-transform': 'scale(0.98)',
        '-ms-transform': 'scale(0.98)',
        '-o-transform': 'scale(0.98)',
    },

    container: {
        width: 260,
        height: 260,
        borderRadius: 4,
    },

    avatar: {
        border: '0.4px solid #707070',

        [theme.breakpoints.up(1600)]: {
            border: '0.5px solid #707070',
        }
    },

    badge: {
        transform: 'scale(1) translate(0, 0)',
        height: 'auto'
    },

    blank: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        boxSizing: 'border-box',
        paddingTop: 31,
        paddingBottom: 31,
        '--border-color': ({ error }: FormImageStylesProps) => error ? theme.palette.error.main : '#acacac',
        backgroundColor: '#f2f2f2',
        backgroundImage: `linear-gradient(to right, var(--border-color) 50%, transparent 50%),
				 linear-gradient(to right, var(--border-color) 50%, transparent 50%),
				 linear-gradient(to bottom, var(--border-color) 50%, transparent 50%),
				 linear-gradient(to bottom, var(--border-color) 50%, transparent 50%)`,
        backgroundPosition: 'left top, left bottom, left top, right top',
        backgroundRepeat: 'repeat-x, repeat-x, repeat-y, repeat-y',
        backgroundSize: '11px 1px, 11px 1px, 1px 11px, 1px 11px',

        [theme.breakpoints.up(1600)]: {
            paddingTop: 42,
            paddingBottom: 42,
        }
    },

    blankSvg: {
        width: '40%',
    },

    blankSpan: {
        color: '#BDBDBD',
    },

    text: {
        fontSize: 12,
        fontFamily: 'Assistant-SemiBold',

        [theme.breakpoints.up(1600)]: {
            fontSize: 17
        }
    },

    blankButton: {
        borderRadius: 6.3,
        padding: '3px 13px',

        [theme.breakpoints.up(1600)]: {
            borderRadius: 8.5,
            padding: '4.25px 17.85px'
        }
    },

    blankButtonLabel: {
        textTransform: 'none'
    },

    input: {
        display: 'none'
    }
}));

const PictureSvg: React.FC<React.SVGProps<SVGSVGElement>> = props => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="165" height="128.333" viewBox="0 0 165 128.333" {...props}>
            <path id="Icon_awesome-images" data-name="Icon awesome-images" d="M137.5,112.25v4.583a13.75,13.75,0,0,1-13.75,13.75h-110A13.75,13.75,0,0,1,0,116.833V43.5A13.75,13.75,0,0,1,13.75,29.75h4.583V89.333A22.943,22.943,0,0,0,41.25,112.25ZM165,89.333V16A13.75,13.75,0,0,0,151.25,2.25h-110A13.75,13.75,0,0,0,27.5,16V89.333a13.75,13.75,0,0,0,13.75,13.75h110A13.75,13.75,0,0,0,165,89.333ZM73.333,29.75A13.75,13.75,0,1,1,59.583,16,13.75,13.75,0,0,1,73.333,29.75ZM45.833,71l15.9-15.9a3.438,3.438,0,0,1,4.861,0L77.917,66.417,116.736,27.6a3.438,3.438,0,0,1,4.861,0l25.069,25.069V84.75H45.833Z" transform="translate(0 -2.25)" fill="#666" opacity="0.38" />
        </svg>
    );
}


