import CircularProgress from '@material-ui/core/CircularProgress';

interface LoadingProps {
    width?: string;
    styles?: React.CSSProperties;
    fixed?: boolean
}

const Loading: React.FC<LoadingProps> = ({ width, styles, fixed }) => {
    return (
        <div style={{
            height: '90vh', position: 'absolute', display: 'flex',
            justifyContent: 'center', flexDirection: 'column',
            backgroundColor: 'rgba(255,255,255,0.7)', top: '10vh',
            marginTop: '75px', width: width, zIndex: 1, ...styles,
            ...(fixed ? { position: 'fixed', top: "0" } : {})
        }} >
            <CircularProgress value={75} disableShrink style={{ margin: 'auto' }} />
        </div>
    )
}
export default Loading;
