import React from "react"
import { Localize, useForm } from "@hilma/forms"
import { Button } from "@material-ui/core"
import { FieldArray } from "formik"
import { ProductFeature } from "../../common/types"
import SignleFeature from './SingleFeature';

interface ProductFeaturesProps {
    id: string,
}

export const ProductFeatures: React.FC<ProductFeaturesProps> = ({ id, }) => {

    const form = useForm();


    return (
        <div className="box">
            <h4>פיצרים של המוצר</h4>
            <div>
                <FieldArray name={id}>
                    {({ remove, push }) => (<div>
                        {form.values.productFeatures?.map((rec: Localize<ProductFeature>, index: number) => {
                            return <SignleFeature id={`${id}[${index}]`} deleteFeature={() => remove(index)} />
                        })}
                        <Button onClick={() => push({ icon: "Desktop" })}>Add Feature</Button>
                    </div>
                    )}

                </FieldArray>
            </div>
        </div>
    );
}

