import React, { useEffect, useState } from "react";
import { createRules, FormCheckBox, GridifyChildren, Localize, useForm, FormFastInput, useAlert, useUpdateOnSubmit } from "@hilma/forms"
import { Button, makeStyles } from "@material-ui/core"
import clsx from "clsx";
import { get as _get } from 'lodash';
import axios from "axios"

import { ProductFeatures } from "./ProductFeatures"
import { Recommenders } from "./Recommenders"
import { ColorPicker } from "./ColorPicker"
import { OrgPurchaseOptions } from "./OrganizationPurchaseOptions";
import { OrderProductPage } from "./OrderPage";
import { ProductVideo } from "./ProductVideo";
import { DownloadLinks } from "./DownloadLinks"
import { Recommender } from "../../common/types"
import ProductThumbnail from "./ProductThumbnail";
import ProdInfoStrip from "./ProdInfoStrip"
import '../styles/EditProduct.scss'
import { useNavigate } from "react-router-dom";
import { Delete } from "@material-ui/icons";
import { GenericInfoPopup } from "../GenericInfoPopup";


export const product_initialValues = {
    visible: false,
    enName: "",
    heName: "",
    infoStrip: {
        image: null,
        heTitle: "",
        enTitle: "",
        heText: "",
        enText: "",
    },
    purchaseText: {
        image: null,
        heTitle: "",
        enTitle: "",
        heText: "",
        enText: "",
    },

    web: "",
    android: "",
    ios: "",

    image: null,
    order: [],
    recommenders: [],
    infoText: null,
    productFeatures: [],
    titleColor: "#c25656",
    removedImages: [],
    thumbnail: {
        categories: [],
        heShortDescription: "",
        enShortDescription: "",
    },
    // styleFile: null,
    // styleFilePath: ''
}

export const productRules = createRules({
    "infoStrip.heText": { required: true },
    // 'infoStrip.enText': { required: true },
    // 'infoStrip.enTitle': { required: true },
    'infoStrip.heTitle': { required: true },
    'infoStrip.image': { required: false, accept: ['image/*'] },
    'thumbnail.image': { required: true, accept: ['image/*'] },
    "thumbnail.categories": { required: true },
    "thumbnail.heShortDescription": { required: true },
    "thumbnail.enShortDescription": { required: true },
    'thumbnail.collaborate': { required: true },
    "purchaseText.image": { required: true, accept: ['image/*'] },
    "purchaseText.heTitle": { required: true },
    // "purchaseText.enTitle": { required: true },
    "purchaseText.heText": { required: true },
    // "purchaseText.enText": { required: true },
    "download.web": { required: true },
    'styleFile': { required: false, accept: ['text/css', 'text/x-scss'] },
    visible: { required: true },
    web: { required: true },
    android: { required: true },
    ios: { required: true },
    order: { required: true },
    recommenders: { required: true },
    infoText: { required: true },
    productFeatures: { required: true },
    titleColor: { required: true },
    removedImages: { required: true },
    enName: { required: true },
    heName: { required: true },
    image: { required: false, accept: ['image/*'] },
})

let i = 0;
interface ProductProps {
    name: string;
    loading: boolean;
    collabs: any[]
}

const EditProductDetails: React.FC<ProductProps> = ({ name, loading, collabs }) => {
    console.log('i:', (i++) / 2) // RN the component renders 6 times on initialization
    const [openDelete, setOpenDelete] = useState(false);
    const classes = useStyles();
    const form = useForm();
    const alert = useAlert();
    const navigate = useNavigate();


    useUpdateOnSubmit(submit);

    function formBeforeSubmit() {
        form.handleSubmit();
        console.log('form.values:', form.values)
        if (!form.isValid) {
            console.log('form.errors:', form.errors);
            alert('ישנם שדות חסרים או שגויים- אנא עברו על הטופס שנית');
        }
    }

    async function submit() {
        if (!form.isValid && !(Object.keys(form.errors).length === 1 && form.errors.order)) {
            console.error("ERRORS", form.errors, form.isValid)
            alert("ישנן שגיאות, הטופס לא נשמר")
            return;
        }
        let values = JSON.parse(JSON.stringify(form.values));//deep copy, don't modify the form.values object.
        ['infoStrip', 'purchaseText', 'thumbnail'].forEach(field => {
            if (form.values[field].imageFile instanceof File) {
                values[field + "Image"] = new File([form.values[field].imageFile], form.values[field].imageFile.name, { type: form.values[field].imageFile.type })
                delete values[field].imageFile;
                delete values[field].image;
            }

        })

        values.recommenders = values.recommenders.map((item: Localize<Recommender & { imageFile?: File }>, index: number) => {
            if (form.values.recommenders[index].imageFile instanceof File) {
                values['recommenderImage' + index] = new File([form.values.recommenders[index].imageFile], (form.values.recommenders[index].imageFile as File as File).name, { type: (form.values.recommenders[index].imageFile as File).type });
                return {
                    ...item, image: undefined, imageFile: undefined
                }
            }
            return item;
        })

        if (values.thumbnail.collaborate) {
            values.thumbnail.collaborateId = values.thumbnail.collaborate?.id || "";
            delete values.thumbnail.collaborate;
        }

        // form.values.styleFile && (values.styleFile = new File([form.values.styleFile], form.values.styleFile.name, { type: form.values.styleFile.type }))

        console.log('values:', values)
        if (!values.video) delete values.video;
        try {
            await axios.post(`/api/product/update`, { files: true, ...values })
            alert(" הטופס נשמר בהצלחה ", "success");
            navigate('/products');
        } catch (err: any) {
            console.log('err: ', err);
            if (err.status == 413) alert('ארע נסיון לשמור קובץ גדול מדי')
            else alert("אירעה שגיאה, הטופס לא נשמר")
        }
    }

    const changeDelete = () => {
        setOpenDelete(prev => !prev);
    }

    const deleteProject = async () => {
        try {
            const res = await axios.delete(`/api/product/delete-by-id?name=${name}`);
        } catch (err) {
            console.log('err: ', err);

        }
        changeDelete();
    }

    /**
     * items: 
     * title+image V
     * images V
     * features V
     * recommenders V
     * purchase-text V
     * video V
     * download links V
     * organizationPurchaseOptions V 
     * category- thumbnail 
     * visible- true/false V
     * thumbnail -V
     * order- V
     */

    return <div className={classes.container}>
        <h3>Edit {name}</h3>
        <GridifyChildren>
            <FormCheckBox id="visible" description={"האם להציג את הפרויקט באתר?"} />
            <div>
                <Button onClick={formBeforeSubmit} className={clsx(classes.button, "save-top-btn")}>
                    שמור
                </Button>
                <Delete className="delete" onClick={changeDelete} />
            </div>
        </GridifyChildren>
        <GridifyChildren>
            <FormFastInput loading={loading} id="heName" description={"שם הפרויקט בעברית"} inputProps={{ maxLength: 50 }} />
            <FormFastInput loading={loading} id="enName" description={"שם הפרויקט באנגלית"} inputProps={{ maxLength: 50 }} />
            <ColorPicker id="infoStrip.titleColor" description="title color" />
        </GridifyChildren>
        <ProductThumbnail id="thumbnail" collabs={collabs} />
        <ProdInfoStrip id="infoStrip" />
        <ProductFeatures id="productFeatures" />
        <Recommenders id="recommenders" />
        <ProdInfoStrip id="purchaseText" />
        <OrgPurchaseOptions id="organizationPurchaseOptions" />
        <DownloadLinks id="download" />
        <OrderProductPage id="order" />
        <ProductVideo id="video" />
        {/* <PurchaseScssFile id="styleFile" /> */}
        <Button onClick={formBeforeSubmit} className={clsx(classes.button, classes.margin)}>
            שמור
        </Button>
        <GenericInfoPopup
            open={openDelete}
            title="הודעת מחיקה"
            infoText="האם ברצונך למחוק פרויקט זה?"
            approve={deleteProject}
            close={changeDelete} />

    </div>
}

export default EditProductDetails;

export const useStyles = makeStyles(theme => ({
    container: {
        direction: "ltr",
        fontSize: '1.2rem',
        margin: 'auto',
        width: '70vw',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        padding: '1vh  5vh',
        boxSizing: 'border-box',
        '&>button>span': {
            fontFamily: 'Assistant-Bold',
            fontSize: '1.2rem'
        }
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        color: "white",
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: '8px',
        padding: ' 0 1.2%',
        height: '2rem',
        fontSize: '1rem',
        margin: "auto",
        // margin: '2rem 0 1rem 0',
        fontFamily: "Assistant-Regular",
        width: "20%",
        '&:hover': {
            backgroundColor: '#fff',
            color: theme.palette.primary.main,
            border: "1px solid",
            borderColor: theme.palette.primary.main
        },
    },

    maxWidth125: {
        maxWidth: '125px'
    },

    margin: {
        margin: "1rem auto",
    },
    noMargin: {
        margin: '0'
    },
    addTutorial: {
        width: 'unset',
        position: 'absolute',
        float: 'right',
        right: '12%'
    }
}))

