
import React, { useState } from 'react';
import { FormFastInput, FormRules, Localize, useForm, withForm } from "@hilma/forms"
import { ProdcutFeatureIcon, ProductFeature } from "../../common/types"
import { FormikValues } from 'formik';
import styles from '../styles/singleFeature.module.scss';
import { Collapse, InputLabel, ListItem } from '@material-ui/core';
import clsx from 'clsx';
import { useMemo } from 'react';
import { get as _get } from 'lodash';

type IconsOption = {
    id: ProdcutFeatureIcon
    value: string
}
const iconsOptions: IconsOption[] = [
    { id: 'Phone', value: 'פלאפון נייד' },
    { id: 'Desktop', value: 'מסך מחשב' },
    { id: 'Statistics', value: 'סטטיסטיקות' },
    { id: 'Report', value: 'דוחות' },
    { id: 'Notification', value: 'התראות' },
    { id: 'VideoScreen', value: 'סרטונים' },
]
interface SignleFeatureProps {
    deleteFeature: () => void,
    id: string
}

const SignleFeature: React.FC<SignleFeatureProps> = ({ id, deleteFeature }) => {
    const [open, setOpen] = useState(false);
    const form = useForm();

    function onselectIcon(iconId: ProdcutFeatureIcon) {
        form.setFieldValue(`${id}.icon`, iconId);
    }

    const feature = useMemo(() => {
        return _get(form.values, id);
    }, [form.values.productFeatures])

    return <div className={styles.edit_feature_container}>
        <ListItem onClick={() => setOpen(v => !v)}>
            {feature.icon && <img src={`/admin-icons/${feature.icon}.svg`} height="40px" width="40px" style={{ margin: "0 1vw" }} />}
            <InputLabel>
                {feature.heTitle || "פיצר חדש- חסר מידע"}
            </InputLabel>
            <img src="/admin-icons/x.svg" height="15px" width="15px" className={styles.removeRow} onClick={deleteFeature} />

        </ListItem>
        <Collapse in={open}>
            <FormFastInput id={`${id}.heTitle`} description="כותרת בעברית" />
            <FormFastInput id={`${id}.heText`} description="טקסט פירוט בעברית" />
            <FormFastInput id={`${id}.enTitle`} description="כותרת באנגלית" />
            <FormFastInput id={`${id}.enText`} description="טקסט פירוט באנגלית" />
            <FeatureIcon onSelect={onselectIcon} />
        </Collapse>
    </div>
}
export default SignleFeature;

// export default withForm()(SignleFeature);

const FeatureIcon: React.FC<{ onSelect: (id: ProdcutFeatureIcon) => void }> = ({ onSelect }) => {
    const [selected, setSelected] = useState<ProdcutFeatureIcon | "">('')

    function iconSelected(id: ProdcutFeatureIcon) {
        setSelected(id);
        onSelect(id);
    }

    return <div className={styles.iconsContainer}>
        {iconsOptions.map(icon => <img height="40px" width="40px" key={icon.id}
            className={clsx(selected === icon.id && styles.selected, styles.icon_option)} src={`/admin-icons/${icon.id}.svg`}
            onClick={() => iconSelected(icon.id)}
            alt={icon.value}
        />)}</div>
}