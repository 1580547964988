import React from "react"
import { Localize, useForm } from "@hilma/forms";
import { Button } from "@material-ui/core";
import { FieldArray } from "formik";
import { Recommender } from "../../common/types";
import { SingleRecommender } from "./SingleRecommender";

interface RecommendersProps {
    id: string,
    key?: string
}

export const Recommenders: React.FC<RecommendersProps> = ({ id, key }) => {

    const form = useForm();

    return (
        <div className="box">
            <h4>המלצות וחוות דעת</h4>
            <FieldArray name={id}>
                {({ remove, push }) => (<div>
                    {form.values.recommenders?.map((rec: Localize<Recommender>, index: number) => {
                        return <SingleRecommender id={`${id}[${index}]`} deleteRecommender={() => remove(index)} />
                    })}
                    <Button onClick={() => push({ })}>Add Recommender</Button>
                </div>
                )}

            </FieldArray>
        </div>
    );

}

