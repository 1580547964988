import { FormFastInput, useForm } from "@hilma/forms";
import { ChangeEvent, FC, useEffect, useState } from "react";
import { get as _get } from 'lodash';

export const ColorPicker: FC<{ id: string, description: string }> = ({ id, description }) => {
    const form = useForm();
    const [color, setColor] = useState(_get(form.values, id));

    function changeColor(e: ChangeEvent<HTMLInputElement>) {
        setColor(e.target.value);
    }

    useEffect(() => {
        if (_get(form.values, id) !== color)
            setColor(_get(form.values, id));
    }, [_get(form.values, id)])

    function onBlur() {
        form.setFieldValue(id, color)
    }

    /**
     * NOTICE- onBlur bug: when bluring from the input-color-dialog, the blur event is not triggering. Only after the second click it blurs.
     * That causes an unlikely behavior: If you change the color with the dialog, and press the save button - it wod't update the value, because 
     * the onSubmit function will run before the onBlur.
     * 
     * We are well aware to this problem, but for now it cannot be solved (unless we render the whole form instead (TONES of renders).
     * And anyway, I don't think that anyone will come to the page, change the color (form the color picker!!) and submit. 
     * Good luck:)
     */

    return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
        <FormFastInput id={id} description={description} value={color} onChange={(e) => setColor(e.target.value)} onBlur={onBlur} />
        <input onClick={() => console.log("color clivk")} type="color" value={color} onChange={changeColor} style={{ margin: "5px 2% 0", height: "2.8em" }} onBlur={onBlur} />
    </div>
}