import { FC, Fragment, useEffect, useMemo, useState } from "react";
import { formatTime, FormDate, FormInput, FormProvider, FormSelect, FormTimeRange, GridifyChildren, Language, Localize, Option, useAlert, useForm } from "@hilma/forms";
import { Button } from "@material-ui/core";
import clsx from "clsx";
import { provide } from '@hilma/tools';
import * as yup from 'yup';
import axios from "axios";

import { useProducts } from "../context/products.context";
import { ProductInfo, Tutorial } from "../common/types";
import trashIcon from '../icons/trash.png'
import { Tutorial as ViewTutorial } from "./Tutorial";
import { useStyles } from "./EditProduct/EditProductDetails";
import { ConfirmPopup } from "./ConfirmPopup";
import Loading from "./Loading";
interface NewTutorialFormProps {
    tutorial?: Localize<Tutorial>
    removeTutorial?: () => void;
    updateTutorial: (newTutorial: Localize<Tutorial>) => void
}
const schema = yup.object().shape({
    heTitle: yup.string().required("שדה חובה"),
    // enTitle: yup.string().required("שדה חובה"),
    heSubtitle: yup.string(),
    // enSubtitle: yup.string(),
    heLocation: yup.string(),
    // enLocation: yup.string(),
    // product: yup.string(),
    link: yup.string().url().required("שדה חובה"),
    date: yup.date().required()
    // timeRange:yup.

});


const NewTutorialForm: FC<NewTutorialFormProps> = ({ tutorial, updateTutorial, removeTutorial }) => {
    const classes = useStyles();
    const form = useForm();
    const alert = useAlert();
    const products = useProducts()
    const [edit, setEdit] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);

    const productsObj = useMemo(() => {
        let obj: Record<string, Localize<ProductInfo>> = {};
        for (let prod of products) {
            obj[prod._id] = prod;
        }
        return obj;
    }, [products])


    useEffect(() => {
        setInitialValues(!tutorial?._id)
    }, [products])


    async function submit() {
        try {
            form.handleSubmit();
            console.log('form.values:', form.values)
            if (!form.isValid) return alert('ישנם שדות לא תקינים');

            let body = Object.assign({}, form.values);
            const range = form.values.timeRange;
            if (range)
                body.timeRange = `${formatTime(range[0], Language.He)} - ${formatTime(range[1], Language.He)}`
            if (body.product && typeof body.product !== "string")
                body.product = body.product.id;

            await axios.post(`/api/tutorials/${tutorial?._id ? 'update-tutorial' : 'create-tutorial'}`, body);

            updateTutorial(body);

            setEdit(false);
            alert("יצירת ההדרכה בוצעה בהצלחה", "success")
        } catch (err) {
            console.log('err: ', err);
            alert('אירעה שגיאה ביצירת ההדרכה')
        }
        finally {
            form.setSubmitting(false)
        }

    }

    function setInitialValues(edit = false) {
        setEdit(edit);
        form.setValues({
            ...tutorial,
            product: {
                id: tutorial?.product,
                value: productsObj[tutorial?.product as string] && productsObj[tutorial?.product as string].heName
            },
            timeRange: tutorial?.timeRange ? tutorial?.timeRange?.split(' - ').map(str => {
                let times = str.split(":");
                return new Date(new Date().setHours(Number(times[0]), Number(times[1])))
            }) : undefined
        });
    }

    function onCancel() {
        if (!tutorial?._id) removeTutorial && removeTutorial();
        else setInitialValues();

    }

    async function closeDeletePopup(confirm: boolean) {
        setOpenPopup(false);
        if (!confirm) return;
        if (tutorial?._id) {
            try {
                const res = await axios.delete('/api/tutorials/delete-tutorial', { params: { id: tutorial._id } })
                if (!res.data) return alert('אירעה שגיאה')
            } catch (error) {
                return alert('אירעה שגיאה')
            }
        }
        alert('ההדרכה נמחקה בהצלחה', 'success');
        return removeTutorial && removeTutorial();
    }

    const options: Option[] = useMemo(() => {
        return products.map(prod => ({ id: prod._id, value: prod.heName }));
    }, [products])


    return <div>
        {form.isSubmitting && <Loading width="100vw" />}
        {edit ?
            <div className={clsx("tutorial", edit ? 'tutorial-edit' : '')}>
                <button onClick={() => setOpenPopup(true)} className="transparent-btn trash"> <img src={trashIcon} height="25px" width="25px" /></button>
                <GridifyChildren>

                    <FormInput id="heTitle" description="כותרת בעברית" />
                    <FormInput id="enTitle" description="כותרת באנגלית" />
                    <FormInput id="heSubtitle" description="תיאור מפגש בעברית" />
                    <FormInput id="enSubtitle" description="תיאור מפגש באנגלית" />

                    <FormInput id="heLocation" description="כתובת בעברית (אופציונלי)" />
                    <FormInput id="enLocation" description="כתובת באנגלית (אופציונלי)" />

                    <FormSelect id="product" description="פרויקט" options={options} />

                    <FormInput id="link" description="לינק להרשמה" />
                    <FormDate id="date" description="תאריך" />
                    <FormTimeRange id="timeRange" description="שעות המפגש" />
                </GridifyChildren>

                <div className="save-btn" style={{}}>


                    <Button onClick={onCancel} className={clsx(classes.button, classes.noMargin, classes.maxWidth125)}>
                        בטל
                    </Button>
                    <Button onClick={submit} className={clsx(classes.button, classes.noMargin, classes.maxWidth125)}>
                        שמור
                    </Button>

                    {/* </GridifyChildren> */}
                </div>
            </div> :
            <Fragment>
                {tutorial &&
                    <Fragment>
                        <ViewTutorial tutorial={tutorial!} name={productsObj[tutorial.product]?.heName} onEditClick={() => setEdit(true)} />
                    </Fragment>}
            </Fragment>
        }
        <ConfirmPopup open={openPopup} content="האם ברצונך למחוק את ההדרכה?" handleClose={closeDeletePopup} />
    </div >
}

export default provide([FormProvider, {
    rules: {}, initialValues: {},
    validationSchema: schema, validateOnBlur: true
}])(NewTutorialForm);


