import { useIsAuthenticated } from "@hilma/auth";
import { Button } from "@material-ui/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as HomePeople } from '../icons/home_people.svg'
import './styles/home.scss'

interface HomepageProps {
}
export const Homepage: React.FC<HomepageProps> = ({ }) => {
    const isAuthenticated = useIsAuthenticated();
    const navigate = useNavigate();
    return <div className='home'>
        <div>

            <h1>ברוכים הבאים!</h1>
            {!isAuthenticated && <div className="login">
                <p>
                    עליך לבצע התחברות על מנת להשתמש במערכת
                </p>
                <Button
                    onClick={() => navigate('/login')}
                    style={{ color: "white", borderColor: "white" }} variant="outlined"
                >
                    להתחברות
                </Button>
            </div>}
        </div>


        <HomePeople className='background-img home-ppl' />
    </div>
}

