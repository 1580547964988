import { error, skyBlue, teal } from "./colors";

export const muiTheme = {
    primary: {
        main: teal
    },
    secondary: {
        main: skyBlue
    },
    error: {
        main: error
    }
}
