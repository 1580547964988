import { FC } from "react";
import { Localize } from "@hilma/forms";
import { useAsyncEffect } from "@hilma/tools";
import { Button, CircularProgress } from "@material-ui/core";
import { useImmer } from "use-immer";
import axios from "axios";
import clsx from "clsx";

import NewTutorialForm from "../components/NewTutorialForm"
import { Tutorial } from "../common/types";
import { useStyles } from "../components/EditProduct/EditProductDetails";
import './styles/CreateTutorials.scss';

export const CreateTutorials: FC = () => {
    const [tutorials, setTutorials] = useImmer<Localize<Tutorial>[] | undefined>(undefined);

    const classes = useStyles();

    useAsyncEffect(async () => {
        const { data } = await axios.get<Localize<Tutorial>[]>('/api/tutorials/all')
        setTutorials(data);
    }, [])

    function addTutorial() {
        setTutorials(draft => { (draft || []).unshift({} as Localize<Tutorial>) })
    }
    return <div id="create-tutorials">
        <span className="top-container">
            <h3 >מפגשים קרובים</h3>
            <Button onClick={addTutorial} className={clsx(classes.button, classes.margin, classes.addTutorial,)}>
                + הוספה
            </Button>
        </span>
        <div className="tutorials-container">
            {!tutorials ? <CircularProgress /> : tutorials.length === 0 ? <div>אין הדרכות באופק הנראה לעין</div> : tutorials?.map((tutorial, index) => <NewTutorialForm
                tutorial={tutorial}
                key={tutorial._id || index}
                removeTutorial={() => setTutorials(draft => { (draft || []).splice(index, 1) })}
                updateTutorial={(newTutorial: Localize<Tutorial>) => setTutorials(draft => { (draft || [])[index] = newTutorial })} />

            )}
        </div>
    </div>
}
