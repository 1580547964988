import { useForm } from "@hilma/forms";
import { useAsyncEffect } from "@hilma/tools";
import { Chip, } from "@material-ui/core";
import axios from "axios";
import { FC, useState } from "react";
import { Category } from "../../common/types/Category.type";

interface ProductCategoriesProps {

}
export const ProductCategories: FC<ProductCategoriesProps> = ({ }) => {
    const [categories, setCategories] = useState<Category[]>([]);
    const form = useForm()


    useAsyncEffect(async () => {
        let { data: categories } = await axios.get<Category[]>('/api/categories');
        setCategories(categories);
    }, [])


    function onChipClick(id: string) {
        let index = form.values.thumbnail.categories.findIndex((item: string) => item == id);
        let copy = { ...form.values.thumbnail };
        if (index !== -1)
            copy.categories.splice(index, 1);
        else copy.categories.push(id);

        form.setFieldValue("thumbnail", copy);
    }

    return <div className="cats-container">
        <div className="cats-title">
            קטגוריות של המוצר:
        </div>
        <div className="cats">

            {categories.map(cat => {
                return <Chip className="cat" key={cat._id} variant={form.values.thumbnail.categories?.includes(cat._id) ? undefined : "outlined"} label={cat.heName}
                    color="primary" onClick={() => onChipClick(cat._id!!)} />
            })}
        </div>

    </div>

}