import { createRules, FormFastInput, FormProvider, FormSelect, GridifyChildren, useAlert, useForm } from '@hilma/forms';
import { provide, useAsyncEffect } from '@hilma/tools';
import { Button, Collapse, ListItem } from '@material-ui/core';
import axios from 'axios';
import React, { FC, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useStyles } from './EditProductDetails'
import * as Yup from 'yup';

interface openState {
    [key: string]: boolean
}

interface purchasesTypesProps {
    name?: string
}

const PurchasesTypesSchema = Yup.object().shape({
    private: Yup.object().shape({
        cost: Yup.number().required('Required').min(0, 'מחיר חייב להיות חיובי'),
        yearsToExpire: Yup.mixed().required('Required'),
        numberOfLicenses: Yup.number().max(10000, "אפשר רק עד 100000 רשיונות").min(0, "מספר רשיונות חיב להיות חיובי").required('Required')
    }),
    institutional: Yup.object().shape({
        cost: Yup.number().required('Required').min(0, 'מחיר חייב להיות חיובי'),
        yearsToExpire: Yup.mixed().required('Required'),
        numberOfLicenses: Yup.number().max(10000, "אפשר רק עד 100000 רשיונות").min(0, "מספר רשיונות חיב להיות חיובי").required('Required')
    }),
    customized: Yup.object().shape({
        cost: Yup.number().required('Required').min(0, 'מחיר חייב להיות חיובי'),
        yearsToExpire: Yup.mixed().required('Required'),
        numberOfLicenses: Yup.number().max(10000, "אפשר רק עד 100000 רשיונות").min(0, "מספר רשיונות חיב להיות חיובי").required('Required')
    })
});


export const purchasesTypesRules = createRules({
    "private.cost": { required: true },
    "private.yearsToExpire": { required: true },
    "private.numberOfLicenses": { required: true },
    "institutional.cost": { required: true },
    "institutional.yearsToExpire": { required: true },
    "institutional.numberOfLicenses": { required: true },
    "customized.cost": { required: true },
    "customized.yearsToExpire": { required: true },
    "customized.numberOfLicenses": { required: true }
})


export const PurchasesTypes: FC<purchasesTypesProps> = ({ name }) => {
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState<openState>({
        private: false,
        institutional: false,
        customized: false
    });

    const form = useForm();
    const alert = useAlert();
    const navigate = useNavigate();
    const classes = useStyles();

    const yearsOptions = useMemo(() => [
        { id: 1, value: 1 },
        { id: 2, value: 2 },
        { id: 3, value: 3 },
        { id: 4, value: 4 },
        { id: 5, value: 5 },
        { id: 6, value: 6 },
        { id: 7, value: 7 },
        { id: 8, value: 8 },
        { id: 9, value: 9 },
        { id: 10, value: 10 },
        { id: 11, value: 0.5 },
    ], []);

    const types = useMemo(() => [
        { type: "private", text: "אדם פרטי", src: "/images/private.png" },
        { type: "institutional", text: "ארגון קטן", src: "/images/organization.png" },
        { type: "customized", text: "מוסד- צרו קשר", src: "/images/contact-us.png" }
    ], []);

    useAsyncEffect(async () => {
        try {
            const { data } = await axios.get(`/api/purchase-type/get-purchases-types-data/${name || ""}`)
            if (Object.entries(data).length === 0) {
                alert("הפרוייקט לא קיים");
                navigate("/products")
                return;
            }
            form.setValues({
                private: {
                    cost: data.private.cost,
                    yearsToExpire: yearsOptions.filter(opt => opt.value === data.private.yearsToExpire)[0],
                    numberOfLicenses: data.private.numberOfLicenses
                },
                institutional: {
                    cost: data.institutional.cost,
                    yearsToExpire: yearsOptions.filter(opt => opt.value === data.institutional.yearsToExpire)[0],
                    numberOfLicenses: data.institutional.numberOfLicenses
                },
                customized: {
                    cost: data.customized.cost,
                    yearsToExpire: yearsOptions.filter(opt => opt.value === data.customized.yearsToExpire)[0],
                    numberOfLicenses: data.customized.numberOfLicenses
                }
            });
            setLoading(false);
        } catch (err) {
            console.log('err: ', err);
            alert("ארעה שגיאה בהבאת פרטי סוגי הרכישות");
        }
    }, [])

    const save = async () => {
        try {
            form.handleSubmit();
            if (!form.isValid) {
                alert('חלק מהשדות לא תקינים');
                return;
            }
            console.log(form.values);
            
            const body = {//todo not always 
                private: {
                    yearsToExpire: form.values.private.yearsToExpire.value,
                    cost: Number(form.values.private.cost),
                    numberOfLicenses: Number(form.values.private.numberOfLicenses)
                },
                customized: {
                    yearsToExpire: form.values.customized.yearsToExpire.value,
                    cost: Number(form.values.customized.cost),
                    numberOfLicenses: Number(form.values.customized.numberOfLicenses)
                },
                institutional: {
                    yearsToExpire: form.values.institutional.yearsToExpire.value,
                    cost: Number(form.values.institutional.cost),
                    numberOfLicenses: Number(form.values.institutional.numberOfLicenses)
                }
            }
            await axios.post(`/api/purchase-type/edit-purchase-type/${name}`, body);
            alert('הפרטים נשמרו בהצלחה', 'success')
        } catch (err) {
            console.log('err: ', err);
            alert("ארעה שגיאה");
        }
    }

    return (
        <div className='purchase-options'>
            {types.map(item => <div key={item.type} className="box" style={{ padding: '.5rem' }}>
                <ListItem onClick={() => setOpen(prev => ({ ...prev, [item.type]: !prev[item.type] }))}>
                    <h3 style={{ marginLeft: "auto" }}>{item.text}</h3>
                    <img src={item.src} height="60px" />
                </ListItem>
                <Collapse in={open[item.type]} style={{ marginTop: "5%" }}>
                    <GridifyChildren>
                        <FormFastInput loading={loading} id={`${item.type}.cost`} description={`מחיר (בשקלים)`} multiline={true} />
                        <FormFastInput loading={loading} id={`${item.type}.numberOfLicenses`} description={`מספר רשיונות מקסימלי`} multiline={true} />
                        <FormSelect disabled={loading} id={`${item.type}.yearsToExpire`} description={`זמן תפוגה (בשנים)`} options={yearsOptions} />
                    </GridifyChildren>

                </Collapse>
            </div>)}
            <Button className={classes.button} style={{ marginTop: '1rem' }} disabled={loading} onClick={save}>
                שמור
            </Button>
        </div>
    )
}

export default provide([FormProvider, {
    initialValues: {},
    rules: purchasesTypesRules, validationSchema: PurchasesTypesSchema,
    validateOnBlur: true
}])(PurchasesTypes);
