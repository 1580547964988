import { createContext, FC, useState } from "react";
import { Localize } from "@hilma/forms";
import { ProductInfo } from "../common/types";
import { useAsyncEffect } from "@hilma/tools";
import axios from "axios";
import { useContext } from "react";
import { useIsAuthenticated } from "@hilma/auth";

export const ProductsContext = createContext<Localize<ProductInfo>[] | null>(null);
export const AddProductToListContext = createContext<((item: Localize<ProductInfo>) => void) | null>(null);

export const ProductsProvider: FC = ({ children }) => {
    const [solutions, setSolutions] = useState<Localize<ProductInfo>[]>([])//Its not translated, so if language - no need to re-fetch.

    const isAuth = useIsAuthenticated();
    useAsyncEffect(async () => {
        if (!solutions.length) {
            const { data } = await axios.get<Localize<ProductInfo>[]>('/api/product?all=true')
            setSolutions(data)
        }
    }, [isAuth])

    const addProductToList = (item: Localize<ProductInfo>) => {
        setSolutions([...solutions, item]);
    }

    return <ProductsContext.Provider value={solutions}>
        <AddProductToListContext.Provider value={addProductToList}>
            {children}
        </AddProductToListContext.Provider>
    </ProductsContext.Provider>

}

export const useProducts = () => {
    const products = useContext(ProductsContext);
    return products || [];
}
export const useAddSolutionToList = () => {
    const func = useContext(AddProductToListContext);
    return func!;
}